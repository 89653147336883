import React, { useState } from 'react'
import { Button, Stack } from '@mui/material'
import { styled } from '@mui/system'
import Language from '../../Language'
import Link from './../../../ui-component/Link'
import ExpandMenu from '../HeaderContent/ExpandMenu'
import { graphql } from 'gatsby'
import { useTranslation } from 'react-i18next'
import LogoSection from './../../LogoSection/index'

const ActiveButton = styled(Button)(({ theme }) => ({
    position: 'relative',
    color: 'rgb(179, 181, 183)',
    '&.active': {
        color: 'white',
        '&:before': {
            content: '""',
            borderRadius: '50%',
            position: 'absolute',
            width: '6px',
            height: '6px',
            left: '-12px',
            opacity: 1,
            backgroundColor: theme.palette.primary.main,
            transition: 'opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
        }
    },
    '&:before': {
        content: '""',
        borderRadius: '50%',
        position: 'absolute',
        width: '6px',
        height: '6px',
        left: '-12px',
        opacity: 0,
        backgroundColor: theme.palette.primary.main,
        transition: 'opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
    },
    '&:hover:before': {
        opacity: 1
    }
}))

const DesktopMenu = () => {
    const [activeButton, setActiveButton] = useState('')

    const handleButtonClick = buttonName => {
        setActiveButton(buttonName)
    }

    const { t } = useTranslation()

    const menuItems = [
        { name: t('aboutus'), to: '/o-nas' },
        { name: t('it'), to: '/systemy-informatyczne' },
        { name: t('servicesx'), to: '/uslugi' },
        { name: t('cooperation'), to: '/wspolpraca' },
        { name: t('blogx'), to: '/blog' }
    ]

    return (
        <>
            <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ width: '100%' }}>
                <LogoSection />

                <Stack
                    direction="row"
                    spacing={3}
                    alignItems="center"
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        flexGrow: '1',
                        alignItems: 'center',
                        height: '100%'
                    }}
                >
                    <Stack
                        direction="row"
                        sx={{
                            display: 'flex',
                            gap: '40px',
                            height: '100%'
                        }}
                    >
                        {menuItems.map((item, index) =>
                            item.type === 'expand' ? (
                                <ExpandMenu key={index} data={item.menu} label={item.name} />
                            ) : (
                                <ActiveButton
                                    key={index}
                                    className={activeButton === item.name ? 'active' : ''}
                                    onClick={() => handleButtonClick(item.name)}
                                    component={Link}
                                    to={item.to}
                                >
                                    {item.name}
                                </ActiveButton>
                            )
                        )}
                    </Stack>
                </Stack>

                <Stack direction="row" spacing={2}>
                    <Language />
                    <Button variant="contained" component={Link} to={'/kontakt'}>
                        Kontakt
                    </Button>
                </Stack>
            </Stack>
        </>
    )
}

export default DesktopMenu

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { ns: { in: ["common", "header"] }, language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`
