exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-components-pages-about-us-index-js": () => import("./../../../src/components/pages/about-us/index.js" /* webpackChunkName: "component---src-components-pages-about-us-index-js" */),
  "component---src-components-pages-contact-index-js": () => import("./../../../src/components/pages/contact/index.js" /* webpackChunkName: "component---src-components-pages-contact-index-js" */),
  "component---src-components-pages-cooperation-index-js": () => import("./../../../src/components/pages/cooperation/index.js" /* webpackChunkName: "component---src-components-pages-cooperation-index-js" */),
  "component---src-components-pages-it-system-index-js": () => import("./../../../src/components/pages/it-system/index.js" /* webpackChunkName: "component---src-components-pages-it-system-index-js" */),
  "component---src-components-pages-services-energy-index-js": () => import("./../../../src/components/pages/services/energy/index.js" /* webpackChunkName: "component---src-components-pages-services-energy-index-js" */),
  "component---src-components-pages-services-energy-transformation-index-js": () => import("./../../../src/components/pages/services/EnergyTransformation/index.js" /* webpackChunkName: "component---src-components-pages-services-energy-transformation-index-js" */),
  "component---src-components-pages-services-index-js": () => import("./../../../src/components/pages/services/index.js" /* webpackChunkName: "component---src-components-pages-services-index-js" */),
  "component---src-components-pages-services-it-index-js": () => import("./../../../src/components/pages/services/it/index.js" /* webpackChunkName: "component---src-components-pages-services-it-index-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-templates-blog-list-js": () => import("./../../../src/templates/blog-list.js" /* webpackChunkName: "component---src-templates-blog-list-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

