import plIcon from '../../../images/icons/pl-flag-icon.svg'
import usIcon from '../../../images/icons/us-flag-icon.svg'

export const langOptions = [
    {
        value: 'en',
        label: 'English',
        icon: usIcon
    },
    {
        value: 'pl',
        label: 'Polish',
        icon: plIcon
    }
]
