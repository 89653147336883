import React from 'react'
import { Box, Container, Grid, Stack, Typography } from '@mui/material'
import Logo from '../../ui-component/Logo'
import Link from '../../ui-component/Link'
import LocalPhoneIcon from '@mui/icons-material/LocalPhone'
import EmailIcon from '@mui/icons-material/Email'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import ApartmentIcon from '@mui/icons-material/Apartment'
import { useTranslation } from 'react-i18next'

const Footer = () => {
    const { t } = useTranslation()

    const footerSections = [
        {
            title: t('servicesx'),
            links: [
                { href: '/uslugi/integracja-automatyki-udostepnianie-systemu-it', text: t('automation') },
                { href: '/systemy-informatyczne', text: t('it') },
                { href: '/uslugi/plany-transformacji-energetycznej-systemowocieplowniczych', text: t('transformation') },
                { href: '/uslugi/zarzadzanie-zuzyciem-energii-w-budynku', text: t('management') }
            ]
        },
        {
            title: t('cooperation'),
            links: [
                { href: '/wspolpraca', text: t('modelsOfCooperation') },
                { href: '/wspolpraca', text: t('training') },
                { href: '/wspolpraca', text: t('universities') },
                { href: '/wspolpraca', text: t('districtHeatingCompanies') }
            ]
        }
    ]
    return (
        <Box component="footer" sx={{ backgroundColor: '#222935' }}>
            <Container
                maxWidth="xl"
                sx={{
                    pt: { xs: 4, sm: 5 },
                    pb: { xs: 4, sm: 5 },
                    px: { xs: 2, sm: 3 },
                    color: 'grey'
                }}
            >
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={6} lg={4}>
                        <Stack direction="column" spacing={2}>
                            <Link href="/">
                                <Logo />
                            </Link>
                            <Typography>
                                NG Heat to wiodący dostawca rozwiązań z zakresu automatyki, zarządzania energią i odnawialnych źródeł
                                energii. Współpracujemy z uczelniami i przedsiębiorstwami ciepłowniczymi, oferując kompleksowe usługi od
                                szkoleń po realizację inwestycji.
                            </Typography>
                        </Stack>
                    </Grid>
                    {footerSections.map((section, index) => (
                        <Grid
                            item
                            xs={12}
                            sm={6}
                            md={6}
                            lg={index === 0 ? 2 : 3}
                            key={section.title}
                            sx={{ display: { lg: 'flex' }, justifyContent: { lg: 'center' } }}
                        >
                            <Stack direction="column" spacing={2}>
                                <Box>
                                    <Typography variant="h6" sx={{ color: 'white', fontSize: '18px', lineHeight: '1', fontWeight: '600' }}>
                                        {section.title}
                                    </Typography>
                                </Box>
                                <Stack direction="column" spacing={1}>
                                    {section.links.map(link => (
                                        <Link
                                            key={link.text}
                                            href={link.href}
                                            sx={{
                                                display: 'block',
                                                color: 'inherit',
                                                '&:hover': { color: 'white' }
                                            }}
                                            style={{ textDecoration: 'none' }}
                                        >
                                            {link.text}
                                        </Link>
                                    ))}
                                </Stack>
                            </Stack>
                        </Grid>
                    ))}
                    <Grid item xs={12} sm={6} md={6} lg={3}>
                        <Box>
                            <Typography
                                variant="h6"
                                sx={{ color: 'white', fontSize: '18px', lineHeight: '1', fontWeight: '600', mb: '12px' }}
                            >
                                Skontaktuj się z nami
                            </Typography>
                        </Box>{' '}
                        <Stack direction="column" spacing={1}>
                            <Stack direction="row" alignItems="center" spacing={1.5}>
                                <ApartmentIcon color="primary" fontSize="small" />
                                <Typography>Centrum Energetyki AGH</Typography>
                            </Stack>
                            <Stack direction="row" alignItems="center" spacing={1.5}>
                                <LocationOnIcon color="primary" fontSize="small" />
                                <Typography>ul. Czarnowiejska 36 lok. C5/017 30-054 Kraków</Typography>
                            </Stack>
                            <Stack direction="row" alignItems="center" spacing={1.5}>
                                <EmailIcon color="primary" fontSize="small" />
                                <Typography>biuro@ngheat.info</Typography>
                            </Stack>
                            <Stack direction="row" alignItems="center" spacing={1.5}>
                                <LocalPhoneIcon color="primary" fontSize="small" />
                                <Typography>+48 511 339 687</Typography>
                            </Stack>
                        </Stack>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}

export default Footer
