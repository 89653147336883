import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'

const Logo = () => {
    return (
        <StaticImage
            src="../../images/logo.webp"
            width={150}
            placeholder="blurred"
            formats={['auto', 'webp', 'avif']}
            alt="ngheat"
            style={{ height: '100%' }}
        />
    )
}

export default Logo
