import React from 'react'
import { Container, useMediaQuery } from '@mui/material'
import MobileMenu from './../MobileMenu/index'
import DesktopMenu from './../DesktopMenu/index'

const HeaderContent = () => {
    const matchesXs = useMediaQuery(theme => theme.breakpoints.down('md'))

    return (
        <>
            <Container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {matchesXs ? <MobileMenu /> : <DesktopMenu />}
            </Container>
        </>
    )
}

export default HeaderContent
