import React from 'react'
import { Box, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack, SwipeableDrawer } from '@mui/material'
import { useState } from 'react'
import MenuIcon from '@mui/icons-material/Menu'
import { IconArticle, IconBinaryTree2, IconHeartHandshake, IconInfoSquareRounded, IconSettings, IconUser } from '@tabler/icons-react'
import LogoSection from '../../LogoSection'
import { useTranslation } from 'react-i18next'
import Link from './../../../ui-component/Link'

const MobileMenu = () => {
    const [isOpen, setIsOpen] = useState(false)

    const toggleDrawer = open => event => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return
        }
        setIsOpen(open)
    }

    const { t } = useTranslation()

    const iconBackColorOpen = 'grey.300'
    const iconBackColor = 'grey.100'

    const menuItems = [
        { name: t('aboutus'), icon: <IconInfoSquareRounded />, to: '/o-nas' },
        { name: t('it'), icon: <IconBinaryTree2 />, to: '/systemy-informatyczne' },
        { name: t('servicesx'), icon: <IconSettings />, to: '/uslugi' },
        { name: t('cooperation'), icon: <IconHeartHandshake />, to: '/wspolpraca' },
        { name: t('blogx'), icon: <IconArticle />, to: '/blog' }
    ]

    return (
        <>
            <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ width: '100%' }}>
                <LogoSection />

                <IconButton
                    disableRipple
                    color="secondary"
                    sx={{ color: 'text.primary', bgcolor: isOpen ? iconBackColorOpen : iconBackColor }}
                    aria-label="open profile"
                    aria-haspopup="true"
                    onClick={toggleDrawer(true)}
                >
                    <MenuIcon fontSize="small" />
                </IconButton>

                <SwipeableDrawer anchor="top" open={isOpen} onClose={toggleDrawer(false)} onOpen={toggleDrawer(true)}>
                    <Box sx={{ width: 'auto' }} role="presentation" onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
                        <List>
                            {menuItems.map((item, index) => (
                                <ListItem key={index} disablePadding component={Link} to={item.to}>
                                    <ListItemButton>
                                        <ListItemIcon>{item.icon}</ListItemIcon>
                                        <ListItemText primary={item.name} />
                                    </ListItemButton>
                                </ListItem>
                            ))}
                        </List>
                    </Box>
                </SwipeableDrawer>
            </Stack>
        </>
    )
}

export default MobileMenu
