import React from 'react'
import { Link as GatsbyLink } from 'gatsby'
import { Link as MUILink } from '@mui/material'

const Link = React.forwardRef((props, ref) => {
    return <MUILink component={GatsbyLink} ref={ref} {...props} />
})

Link.displayName = 'Link'

export default Link
