import React, { useRef, useState } from 'react'

import { useTheme } from '@mui/material/styles'
import { Box, ClickAwayListener, IconButton, List, ListItemButton, ListItemIcon, ListItemText, Paper, Popper } from '@mui/material'
import { Link, useI18next } from 'gatsby-plugin-react-i18next'

import Transitions from '../../../components/ui-component/Transitions'
import { useTranslation } from 'react-i18next'
import { langOptions } from './langOptions'
import MainCard from './../../cards/MainCard'

const Language = () => {
    const theme = useTheme()
    const { i18n } = useTranslation()
    const { originalPath } = useI18next()

    const anchorRef = useRef(null)
    const [open, setOpen] = useState(false)
    const handleToggle = () => {
        setOpen(prevOpen => !prevOpen)
    }

    const handleClose = event => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return
        }
        setOpen(false)
    }

    const findIcon = langOptions.find(lang => lang.value === i18n.language)?.icon

    const iconBackColorOpen = 'grey.300'
    const iconBackColor = 'rgba(255, 255, 255, 0.08)'

    return (
        <Box sx={{ flexShrink: 0, ml: 0.75 }}>
            <IconButton
                disableRipple
                color="secondary"
                sx={{ color: 'text.primary', bgcolor: open ? iconBackColorOpen : iconBackColor }}
                aria-label="open profile"
                ref={anchorRef}
                aria-controls={open ? 'profile-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
            >
                <img src={findIcon} alt={langOptions[0].label} />
            </IconButton>
            <Popper
                placement="bottom"
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 9]
                            }
                        }
                    ]
                }}
            >
                {({ TransitionProps }) => (
                    <Transitions type="fade" in={open} {...TransitionProps}>
                        {open && (
                            <Paper
                                sx={{
                                    boxShadow: theme.customShadows.z1,
                                    width: 140
                                }}
                            >
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MainCard elevation={0} border={false} content={false} sx={{}}>
                                        {open && (
                                            <>
                                                <List
                                                    component="nav"
                                                    sx={{
                                                        p: 0,
                                                        '& .MuiListItemIcon-root': { minWidth: 32, color: theme.palette.grey[500] },
                                                        '& .MuiListItemText-root': { textAlign: 'center' }
                                                    }}
                                                >
                                                    {langOptions.map((item, index) => (
                                                        <React.Fragment key={index}>
                                                            <ListItemButton component={Link} to={originalPath} language={item.value}>
                                                                <ListItemIcon>
                                                                    <Box component="img" alt={item.label} src={item.icon} />
                                                                </ListItemIcon>
                                                                <ListItemText primary={item.label} />
                                                            </ListItemButton>
                                                        </React.Fragment>
                                                    ))}
                                                </List>
                                            </>
                                        )}
                                    </MainCard>
                                </ClickAwayListener>
                            </Paper>
                        )}
                    </Transitions>
                )}
            </Popper>
        </Box>
    )
}

export default Language
